import { Entry } from "../entry";

export const cityOfDelusionEntry: Entry = {
    title: "City of Delusion (Muse)",
    date: "16.11.21",
    content: `
# City of Delusion

As played by Muse on the album *Black Holes and Revelations* in 2006.


## Structure

~~~json
0   - 3   | 4  | Intro              | G
4   - 19  | 16 | Verse              | V, G
20  - 35  | 16 | Verse              | all
36  - 57  | 22 | Chorus             | all
58  - 61  | 4  | Interlude          | B
62  - 77  | 16 | Verse              | all
78  - 99  | 22 | Chorus             | all
100 - 103 | 4  | Interlude          | G
104 - 119 | 16 | Verse Solo Trumpet | all
120 - 141 | 22 | Verse              | all
~~~

V: Vocal
G: Guitar
B: Bass

<br>

## Changes

### Intro/Interlude

In intro play Dm/A instead of Dm

~~~json
Dm  A   | Dm  A   | Dm  A   | Dm  A
~~~

### Verse

~~~json
Dm     A     | Dm     A       | Dm     A    | Dm     A
Dm     A7    | Dm     A7b9    | Dm     A7   | Dm     A
A    Bb Bb13 | A      Bb Bb13 | A      A/G  | Bb/F   A/E
Dm     A     | Dm     A       | Dm     A    | Dm    (A)
~~~
<br>

### Chorus


~~~json
F              | A/C#           | Dm         | A            |
Gm             | F              | A/E        | Dm           | A |
Gm             | F              | A/C#       | Dm  1)       | A |
Gm             | F              | A/E        | Dm           |
A    Bb Bb13   | A      Bb Bb13 | A      A/G | Bb/F    A/E  |
~~~

1\) Unisono mit Voc

<br>


## Lyrics

~~~
Stay away from me
Build a fortress and shield your beliefs
Touch the divine
As we fall

In line
Can I believe
When I don't trust?
All your theories turn to dust
I choose to hide
From the all seeing eye

Destroy this
City of delusion
Break
These


Walls
Down
I will avenge!
And justify my reasons
With your blood
You will not rest

Settle for less
Until you guzzle and squander what's left
Do not deny
That you live


And let die
Destroy this
City of delusion
Break
These
Walls
Down
I will avenge!
And justify my reasons
With your blood
Destroy this
City of delusion
Break
These
Walls
Down
I will avenge!
And justify my reasons
With your blood
~~~~

16.11.21
`
  }
