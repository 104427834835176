import { Entry } from "../entry";

export const youWillBeInMyHeart: Entry = {
    title: "You'll be in my Heart (Phil Collins)",
    date: "19.11.21",
    content: `
# You'll be in my Heart

As played by Phil Collins in the following Youtube Video

https://www.youtube.com/watch?v=CpZ0VPAxLh0

## Play Along

<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1353735616&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/pascalweiss" title="Pascal Weiß" target="_blank" style="color: #cccccc; text-decoration: none;">Pascal Weiß</a> · <a href="https://soundcloud.com/pascalweiss/sets/in-my-heart" title="In My Heart" target="_blank" style="color: #cccccc; text-decoration: none;">In My Heart</a></div>

## Structure

~~~
 4  | Intro    | Gb
 8  | Verse1   | Gb
 8  | Verse2   | Gb
 9  | Chorus1  | Eb
 8  | Chorus2  | Eb
 8  | Verse2   | Gb
 8  | Chorus1  | Eb
 8  | Bridge   | Ab
 8  | Bridge   | Ab
 8  | Chorus   | F   (highest note: A)
 8  | Chorus   | F
 14 | Outro    | F
~~~

## Changes

### Intro

~~~
Gb | % |  %  | %
~~~

### Verse1

~~~
Gb | % |  %  | %
Cb | % | Abm | Db
~~~

### Verse2

~~~
Gb | Db Gb | Gb  | Db  Gb
Cb | %     | Abm | Db  Bb
~~~

Db not in first chorus
Bb only before chorus


### Chorus1 (Eb)

~~~
Eb  | Ab | Bb | Gm
Cm7 | Ab | Db | Bb
~~~

### Chorus2 (Eb)

~~~
Eb  | Ab | Bb | Gm
Cm7 | Ab | Db | Bb Ab | Bb
~~~


### Bridge1 (Ab)

~~~
Absus2 Ab | Eb/Bb  Ab | Fm | Eb Fm
Cm        | Cm        | Db | Db
~~~

### Bridge2 (Ab)

~~~
Absus2 Ab | Eb/Bb  Ab | Fm | Eb Fm
Cm        | Cm        | Db | Eb Bb
~~~



## Changes (numeral)

### Verse
~~~
I  | I V | I  | I V
IV |  %  | II | V (III-dur)
~~~

### Chorus
~~~
I     | IV | V    | III(m)
VI(m) | IV | VIIb | V
~~~


## Lyrics

~~~

(Verse)
Come stop your crying
It will be alright
Just take my hand
Hold it tight
I will protect you
From all around you
I will be here
Don't you cry

(Verse)
For one so small
You seem so strong
My arms will hold you
Keep you safe and warm
This bond between us
Can't be broken
I will be here don't you cry

(Chorus)
'Cause you'll be in my heart
Yes, you'll be in my heart
From this day on
Now and forever more

(Corus)
You'll be in my heart
No matter what they say
You'll be here in my heart
Always
Always

(Verse)
Why can't they understand
The way we feel
They just don't trust
What they can't explain
I know we're different
But deep inside us
We're not that different at all

(Chorus)
And you'll be in my heart
Yes, you'll be in my heart
From this day on
Now and forever more

(Bridge)
Don't listen to them
'Cause what do they know?
We need each other
To have, to hold
They'll see in time
I know

(Bridge)
When destiny calls you
You must be strong
I may not be with you
But you've got to hold on
They'll see in time
I know
We'll show them together

(Chorus)
'Cause you'll be in my heart
Believe me, you'll be in my heart
I'll be there from this day on
Now and forever more

(Chorus)
Ooh, you'll be in my heart
No matter what they say
I'll be with you
You'll be here in my heart
I'll be there always

(Outro)
Always
I'll be with you
I'll be there for you always
Always and always

Just look over your shoulder
Just look over your shoulder
Just look over your shoulder
I'll be there always
Always
~~~

## Keys
Provides structure and harmony. But not really arranged for piano, sorry<br>
#künstlerischeFreiheit

**[sheets](assets/in-my-heart/in_my_heart.pdf)**<br>
**[midi](assets/in-my-heart/in_my_heart_midi.zip)**

19.11.21


`
}
