<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="example-toolbar">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="example-app-name">Pascal's Blog</h1>
  </mat-toolbar>

  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
  >
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
    >
      <mat-nav-list>
        <a
          mat-list-item
          routerLink="."
          *ngFor="let article of entries"
          (click)="clicked(article)"
          >{{ article.title }}</a
        >
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="content-container">
      <!-- <div class="article"[innerHTML]="activeArticle"></div> -->
      <div markdown [data]="activeArticle"></div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
