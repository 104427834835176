import { youWillBeInMyHeart } from './entries/you-will-be-in-my-heart.entry'
import { cityOfDelusionEntry } from './entries/city-of-delusion.entry';
import { shutterControllerEntry } from './entries/shutter-controller.entry';
import { Entry } from './entry';

export const fixture: Entry[] = [
  youWillBeInMyHeart,
  cityOfDelusionEntry,
  shutterControllerEntry,
]
